import React from 'react';
import { HashRouter, Route } from 'react-router-dom';
import NavBar from './components/navbar.container.js';
import Home from './components/home.container.js';
import Imprint from './components/imprint.container.js';
import Privacy from './components/privacy.container.js';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min.js';

function App() {
  return (
    <div>
      <HashRouter>
          <div>
            <div id="main" className="container-fluid">
                <div id="content">
                  <NavBar key="navbarComponent" />
                  <div id="contentAfterNav" class="container">
                    <Route exact={true} path="/" component={Home}/>
                    <Route exact={true} path="/impressum" component={Imprint}/>
                    <Route exact={true} path="/datenschutz" component={Privacy}/>
                  </div>
                </div>
            </div>
          </div>
        </HashRouter>
    </div>
  );
}

export default App;
