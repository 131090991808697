import React, { Component } from 'react';
import styled from 'styled-components';
import logo from '../images/logo.png';

class NavBar extends Component {
	constructor(props) {
  		super(props);
  		this.state = {
  		};
    }
      
	render() {
    	return (
            <div>
                <nav class="navbar navbar-expand-lg navbar-dark navbarBackground">
                    <a class="navbar-brand navbarLogoBackground" href="#">
                        <img src={logo} className="img-fluid logo" alt="Logo" />
                    </a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav mr-auto">
                            <li class="nav-item active">
                                <a class="nav-link" href="#">Home</a>
                            </li>
                            <li class="nav-item active">
                                <a class="nav-link" href="#impressum">Impressum</a>
                            </li>
                            <li class="nav-item active">
                                <a class="nav-link" href="#datenschutz">Datenschutz</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
    	);
  	}
}

export default NavBar;