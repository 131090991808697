import React, { Component } from 'react';
import styled from 'styled-components';
import EmailAddress from './emailaddress.component';

class Home extends Component {
	constructor(props) {
  		super(props);
  		this.state = {
  		};
    }
      
	render() {
    	return (
            <div className="text-center">
				Diese Seite wird aktuell überarbeitet. 
				<br />
				Wenn Sie Fragen zu unseren Dienstleistungen haben kontaktieren Sie uns gerne per Mail.
				<br />
				<br />
				<EmailAddress classNames={""} emailAddress={"info@ITKT-S.de"} key={"infoEmailAdress"} />
            </div>
    	);
  	}
}

export default Home;