import React, { Component } from 'react';
import EmailAddress from './emailaddress.component';
import styled from 'styled-components';

class Home extends Component {
	constructor(props) {
  		super(props);
  		this.state = {
  		};
    }
      
	render() {
    	return (
            <div class="text-center">
                <h2>Impressum</h2>
                <br />
                <h3>Adresse</h3>
                <address>
                ITKT - Schmelter
                <br /><br />
                Offerkampweg 6
                <br />
                40880 Ratingen
                </address>
                <br />
                <br />
                Inhaber: Markus Schmelter
                <br />
                Tel.: <a href="tel:+4921021465508">(0 21 02)  146 55 08</a>
                <br />
                Fax: (0 21 02) 146 55 16
                <br />
                eMail: <EmailAddress classNames={""} emailAddress={"info@ITKT-S.de"} key={"infoEmailAdress"} />
                <br /><br />
                Steuer-Nr.: 147/5236/4006
                <br />
                USt-IdNr.: DE815452160
                <br />
                <br />
                Trotz sorgfältiger Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
            </div>
    	);
  	}
}

export default Home;