import React, { Component } from 'react';
import styled from 'styled-components';

class EmailAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    handleClick(event) {
        event.preventDefault();
        document.location.href = "mailto:" + this.props.emailAddress;
    }

    render() {
        let displayAddress = '';
        if(this.props.emailAddress !== undefined) {
            displayAddress = this.props.emailAddress.replace('@','<span>(at)</span>').replace('.','<span class="displayNone">Some random string</span>.');
        }
        return (
            <a ref="emailAddress" href={""} className={this.props.classNames} onClick={this.handleClick.bind(this)}><span dangerouslySetInnerHTML={{__html: displayAddress}}></span></a>
        );
    }
}

export default EmailAddress;